import { format } from "date-fns";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import gsap from "gsap";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { MdKeyboardArrowLeft } from "react-icons/md";
import BlogsCatalogue from "../components/BlogsCatalogue";
import Career from "../components/Career";
import CaseStudies from "../components/CaseStudies";
import CaseStudiesCatalogue from "../components/CaseStudiesCatalogue";
import CaseStudyContentSection from "../components/CaseStudyContentSection";
import Cta1 from "../components/Cta1";
import Faq from "../components/Faq";
import FeaturedBlogs from "../components/FeaturedBlogs";
import FeaturedCaseStudy from "../components/FeaturedCaseStudy";
import Form1 from "../components/Form1";
import Form2 from "../components/Form2";
import Form3 from "../components/Form3";
import Form4 from "../components/Form4";
import Gallery1 from "../components/Gallery1";
import Gallery2 from "../components/Gallery2";
import GridLayout1 from "../components/GridLayout1";
import GridLayout2 from "../components/GridLayout2";
import GridLayout3 from "../components/GridLayout3";
import Header1 from "../components/Header1";
import Header2 from "../components/Header2";
import Header3 from "../components/Header3";
import IconsLayout1 from "../components/IconsLayout1";
import IconsLayout2 from "../components/IconsLayout2";
import IconsLayout3 from "../components/IconsLayout3";
import IconsLayout4 from "../components/IconsLayout4";
import IconsLayout5 from "../components/IconsLayout5";
import Layout from "../components/layout";
import Location from "../components/Location";
import Logos from "../components/Logos";
import MultiStepForm from "../components/MultiStepForm";
import Quote from "../components/Quote";
import SectionLayout1 from "../components/SectionLayout1";
import SectionLayout2 from "../components/SectionLayout2";
import SectionLayout3 from "../components/SectionLayout3";
import SectionLayout4 from "../components/SectionLayout4";
import SectionLayout5 from "../components/SectionLayout5";
import SectionLayout6 from "../components/SectionLayout6";
import SectionLayout7 from "../components/SectionLayout7";
import StatsLayout1 from "../components/StatsLayout1";
import StatsLayout2 from "../components/StatsLayout2";
import StatsLayout3 from "../components/StatsLayout3";
import Team1 from "../components/Team1";
import Team2 from "../components/Team2";
import TestimonialsVideo from "../components/TestimonialsVideo";
import TestimonialsVideoWritten from "../components/TestimonialsVideoWritten";
import TestimonialsWritten from "../components/TestimonialsWritten";
import PssStats from "../components/PssStats";

const CaseTemplate = ({ data: { wpPage, site } }) => {
	const siteUrl = site?.siteMetadata?.siteUrl;
	const [activeRole, setActiveRole] = useState(null);

	const { seoFields } = wpPage;

	const handleMouseEnter = (e, colour) => {
		gsap.to(e.target, { color: colour, duration: 0.1 });
	};

	const handleMouseLeave = (e) => {
		gsap.to(e.target, { color: "#000", duration: 0.1 });
	};

	const featuredCaseStudyComponent = wpPage?.pageFields?.components?.find(
		(component) =>
			component?.fieldGroupName ===
			"CaseStudy_Pagefields_Components_FeaturedCaseStudy"
	);
	const featuredCaseStudy = featuredCaseStudyComponent?.caseStudy;

	const publishedDate = format(new Date(wpPage.date), "dd MMM yyyy");

	const featuredImageData =
		wpPage.caseStudyFields.featuredImage?.localFile?.childImageSharp
			?.gatsbyImageData;

	const componentMap = {
		CaseStudy_Pagefields_Components_Header1: (data) => (
			<Header1
				heading={data.heading}
				body={data.body}
				button1={data.button1}
				button2={data.button2}
				mediaType={data.mediaType}
				video={data.video?.mediaItemUrl}
				videoDesktop={data.videoDesktop?.mediaItemUrl}
				backgroundImage={
					data.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
				overlayColour={data.overlayColour}
			/>
		),
		CaseStudy_Pagefields_Components_Logos: (data) => (
			<Logos logos={data.items} />
		),
		CaseStudy_Pagefields_Components_Header2: (data) => (
			<Header2
				heading={data.heading}
				body={data.body}
				backgroundImage={
					data.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
			/>
		),
		CaseStudy_Pagefields_Components_Header3: (data) => (
			<Header3
				heading={data.heading}
				body={data.body}
				button1={data.button1}
				button2={data.button2}
				backgroundImage={
					data.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
			/>
		),
		CaseStudy_Pagefields_Components_TestimonialsVideoWritten: (data) => (
			<TestimonialsVideoWritten
				mediaType={data.mediaType}
				image={data.image}
				body={data.body}
				companyName={data.companyName}
				position={data.position}
				name={data.name}
				logo={data.logo?.sourceUrl}
				video={data.video?.mediaItemUrl}
				testimonials={data.testimonials}
			/>
		),
		CaseStudy_Pagefields_Components_TestimonialsVideo: (data) => (
			<TestimonialsVideo
				body={data.body}
				companyName={data.companyName}
				position={data.position}
				name={data.name}
				logo={data.logo?.sourceUrl}
				video={data.video?.mediaItemUrl}
			/>
		),
		CaseStudy_Pagefields_Components_TestimonialsWritten: (data) => (
			<TestimonialsWritten testimonials={data.testimonials} />
		),
		CaseStudy_Pagefields_Components_IconsLayout1: (data) => (
			<IconsLayout1
				heading={data.heading}
				body={data.body}
				button={data.button}
				items={data.items}
			/>
		),
		CaseStudy_Pagefields_Components_IconsLayout2: (data) => (
			<IconsLayout2 heading={data.heading} items={data.items} />
		),
		CaseStudy_Pagefields_Components_IconsLayout3: (data) => (
			<IconsLayout3 heading={data.heading} items={data.items} />
		),
		CaseStudy_Pagefields_Components_IconsLayout4: (data) => (
			<IconsLayout4 industries={data.industries} />
		),
		CaseStudy_Pagefields_Components_IconsLayout5: (data) => (
			<IconsLayout5 heading={data.heading} items={data.items} />
		),
		CaseStudy_Pagefields_Components_StatsLayout1: (data) => (
			<StatsLayout1
				heading={data.heading}
				button={data.button}
				stat1={data.stat1}
				stat2={data.stat2}
				stat3={data.stat3}
				backgroundImage={
					data.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
			/>
		),
		CaseStudy_Pagefields_Components_StatsLayout2: (data) => (
			<StatsLayout2
				heading={data.heading}
				button={data.button}
				body={data.body}
				stat1={data.stat1}
				stat2={data.stat2}
				stat3={data.stat3}
				backgroundImage={
					data.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
			/>
		),
		CaseStudy_Pagefields_Components_StatsLayout3: (data) => (
			<StatsLayout3
				heading={data.heading}
				body={data.body}
				button={data.button}
				stat1={data.stat1}
				stat2={data.stat2}
				stat3={data.stat3}
				stat4={data.stat4}
				backgroundImage={
					data.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
			/>
		),
		CaseStudy_Pagefields_Components_Cta1: (data) => (
			<Cta1
				heading={data.heading}
				body={data.body}
				colour={data.colour}
				button={data.button}
				image={data.image?.sourceUrl}
			/>
		),
		CaseStudy_Pagefields_Components_Quote: (data) => (
			<Quote body={data.body} colour={data.colour} />
		),
		CaseStudy_Pagefields_Components_CaseStudies: (data) => (
			<CaseStudies caseStudies={data.caseStudies} />
		),
		CaseStudy_Pagefields_Components_SectionLayout1: (data) => (
			<SectionLayout1
				smallHeading={data.smallHeading}
				heading={data.heading}
				body={data.body}
				button={data.button}
				backgroundImage={data.backgroundImage}
			/>
		),
		CaseStudy_Pagefields_Components_SectionLayout2: (data) => (
			<SectionLayout2
				heading={data.heading}
				body={data.body}
				button={data.button}
				colour={data.colour}
				colour2={data.colour2}
				image={data.image?.localFile?.childImageSharp?.gatsbyImageData}
			/>
		),
		CaseStudy_Pagefields_Components_SectionLayout3: (data) => (
			<SectionLayout3
				heading={data.heading}
				body={data.body}
				button={data.button}
				colour={data.colour}
				colour2={data.colour2}
				image={data.image?.localFile?.childImageSharp?.gatsbyImageData}
			/>
		),
		CaseStudy_Pagefields_Components_SectionLayout4: (data) => (
			<SectionLayout4
				heading={data.heading}
				body={data.body}
				button={data.button}
				colour={data.colour}
				colour2={data.colour2}
				image={data.image?.localFile?.childImageSharp?.gatsbyImageData}
				column1={data.column1}
				column2={data.column2}
			/>
		),
		CaseStudy_Pagefields_Components_SectionLayout5: (data) => (
			<SectionLayout5
				heading={data.heading}
				body={data.body}
				button={data.button}
				colour={data.colour}
				colour2={data.colour2}
				image={data.image?.localFile?.childImageSharp?.gatsbyImageData}
				column1={data.column1}
				column2={data.column2}
			/>
		),
		CaseStudy_Pagefields_Components_SectionLayout6: (data) => (
			<SectionLayout6
				heading={data.heading}
				body={data.body}
				button={data.button}
				colour={data.colour}
				colour2={data.colour2}
				items={data.items}
				image={data.image?.localFile?.childImageSharp?.gatsbyImageData}
			/>
		),
		CaseStudy_Pagefields_Components_SectionLayout7: (data) => (
			<SectionLayout7
				heading={data.heading}
				body={data.body}
				button={data.button}
				colour={data.colour}
				colour2={data.colour2}
				image1={data.image1?.localFile?.childImageSharp?.gatsbyImageData}
				image2={data.image2?.localFile?.childImageSharp?.gatsbyImageData}
				image3={data.image3?.localFile?.childImageSharp?.gatsbyImageData}
			/>
		),
		CaseStudy_Pagefields_Components_Gallery1: (data) => (
			<Gallery1 images={data.images} />
		),
		CaseStudy_Pagefields_Components_Gallery2: (data) => (
			<Gallery2 images={data.images} />
		),
		CaseStudy_Pagefields_Components_Team1: (data) => (
			<Team1
				heading={data.heading}
				body={data.body}
				teamMembers={data.teamMembers}
				button={data.button}
			/>
		),
		CaseStudy_Pagefields_Components_Team2: (data) => (
			<Team2
				heading={data.heading}
				body={data.body}
				teamMembers={data.teamMembers}
				secondHeading={data.secondHeading}
				secondBody={data.secondBody}
				button={data.button}
			/>
		),
		CaseStudy_Pagefields_Components_FeaturedBlogs: (data) => (
			<FeaturedBlogs
				heading={data.heading}
				body={data.body}
				colour={data.colour}
			/>
		),
		CaseStudy_Pagefields_Components_BlogsCatalogue: (data) => (
			<BlogsCatalogue
				heading={data.heading}
				body={data.body}
				colour={data.colour}
			/>
		),
		CaseStudy_Pagefields_Components_FeaturedCaseStudy: (data) => (
			<FeaturedCaseStudy caseStudy={data.caseStudy} />
		),
		CaseStudy_Pagefields_Components_CaseStudiesCatalogue: (data) => (
			<CaseStudiesCatalogue
				featuredCaseStudy={featuredCaseStudy}
				colour={data.colour}
			/>
		),
		CaseStudy_Pagefields_Components_Form1: (data) => (
			<Form1 heading={data.heading} />
		),
		CaseStudy_Pagefields_Components_Form2: (data) => (
			<Form2 heading={data.heading} body={data.body} />
		),
		CaseStudy_Pagefields_Components_Form3: (data) => (
			<Form3
				heading={data.heading}
				image={data.image?.localFile?.childImageSharp?.gatsbyImageData}
				forgotPasswordLink={data?.forgotPasswordLink}
			/>
		),
		CaseStudy_Pagefields_Components_PssStats: (data) => (
			<PssStats stats={data.stats} />
		),
		CaseStudy_Pagefields_Components_Form4: (data) => (
			<Form4
				heading={data.heading}
				image={data.image?.localFile?.childImageSharp?.gatsbyImageData}
				link={data?.link}
				subHeading={data?.subheading}
			/>
		),
		CaseStudy_Pagefields_Components_Career: (data) => (
			<Career
				activeRole={activeRole}
				setActiveRole={setActiveRole}
				colour={data.colour}
			/>
		),
		CaseStudy_Pagefields_Components_MultiStepForm: (data) => (
			<MultiStepForm role={activeRole} colour={data.colour} />
		),
		CaseStudy_Pagefields_Components_Location: (data) => (
			<Location heading={data.heading} locations={data.locations} />
		),
		CaseStudy_Pagefields_Components_Faq: (data) => (
			<Faq
				heading={data.heading}
				body={data.body}
				secondHeading={data.secondHeading}
				secondBody={data.secondBody}
				faqs={data.faqs}
				button={data.button}
				colour={data.colour}
			/>
		),
		CaseStudy_Pagefields_Components_GridLayout1: (data) => (
			<GridLayout1
				heading={data.heading}
				button={data.button}
				colour1={data.colour1}
				colour2={data.colour2}
				colour3={data.colour3}
				colour4={data.colour4}
				colour5={data.colour5}
				colour6={data.colour6}
				industries={data.industries}
			/>
		),
		CaseStudy_Pagefields_Components_GridLayout2: (data) => (
			<GridLayout2 heading={data.heading} items={data.items} />
		),
		CaseStudy_Pagefields_Components_GridLayout3: (data) => (
			<GridLayout3 items={data.items} />
		),
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: `${seoFields?.metaTitle}`,
				item: {
					url: `${siteUrl}/${wpPage.slug}`,
					id: `${siteUrl}/${wpPage.slug}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.metaTitle || wpPage?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/blog/${wpPage.slug}`,
					title: `${seoFields?.opengraphTitle || wpPage?.title}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.sourceUrl}`,
							width: `${seoFields?.image?.mediaDetails.width}`,
							height: `${seoFields?.image?.mediaDetails.height}`,
							alt: `${seoFields?.image?.altText}`,
						},
					],
				}}
			/>

			<section className="case-study-top-section py-5">
				<Container>
					<Row className="pb-md-5 pb-3">
						<Col>
							<div className="d-flex align-items-center">
								<span style={{ color: wpPage.caseStudyFields.colour }}>
									<MdKeyboardArrowLeft className="fs-5 me-2" />
								</span>
								<Link
									onMouseEnter={(e) =>
										handleMouseEnter(e, wpPage.caseStudyFields.colour)
									}
									style={{ color: "black" }}
									onMouseLeave={handleMouseLeave}
									className="outfit-regular text-decoration-none  mb-0 pb-0"
									to={`/case-studies`}
								>
									View All
								</Link>
							</div>
						</Col>
					</Row>
					<Row className="mb-4">
						<Col xs={6} className="position-relative">
							<div className="d-flex align-items-center">
								<div className="p-1 px-2  position-relative">
									<div
										style={{
											background: wpPage.caseStudyFields.colour,
											opacity: 0.1,
										}}
										className="position-absolute w-100 h-100 start-0 top-0"
									></div>
									<p
										style={{ zIndex: 2 }}
										className="outfit-bold position-relative py-0 my-0"
									>
										Success
									</p>
								</div>

								<span className="ms-3">
									{wpPage.caseStudyFields.numberOfMinRead} min read
								</span>
							</div>
						</Col>
						<Col xs={6} className="text-end">
							<img
								src={wpPage.caseStudyFields.logo.sourceUrl}
								alt={wpPage.caseStudyFields.logo.altText}
								style={{ maxWidth: "100px" }}
							/>
						</Col>
					</Row>

					<Row className="mb-lg-5 mb-3">
						<Col>
							<h1
								style={{ color: wpPage.caseStudyFields.colour }}
								className="display-5"
							>
								{wpPage.title}
							</h1>
						</Col>
					</Row>

					{featuredImageData && (
						<Row className="mb-4">
							<Col>
								<GatsbyImage
									image={featuredImageData}
									style={{ height: "600px", borderRadius: "12px" }}
									alt={
										wpPage.caseStudyFields.featuredImage.altText ||
										"Case Study Featured Image"
									}
									className="w-100 d-none d-md-block"
								/>
								<GatsbyImage
									image={featuredImageData}
									style={{ height: "400px", borderRadius: "12px" }}
									alt={
										wpPage.caseStudyFields.featuredImage.altText ||
										"Case Study Featured Image"
									}
									className="w-100 d-md-none"
								/>
							</Col>
						</Row>
					)}

					<Row className="mb-3 g-5 align-items-center">
						<Col xs="auto" className=" ">
							<p className="pb-0 mb-1">Written by</p>
							<p
								style={{ color: wpPage.caseStudyFields.colour }}
								className="mb-0 outfit-medium"
							>
								{wpPage.caseStudyFields.authorName}
							</p>
						</Col>
						<Col xs="auto">
							<p className="pb-0 mb-1">Published on</p>
							<p
								style={{ color: wpPage.caseStudyFields.colour }}
								className="mb-0 outfit-medium"
							>
								{publishedDate}
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<CaseStudyContentSection site={site} wpPage={wpPage} />
			{wpPage?.pageFields?.components?.map((component, index) => {
				const renderComponent = componentMap[component?.fieldGroupName];
				return renderComponent ? (
					<div key={index}>{renderComponent(component)}</div>
				) : null;
			})}
		</Layout>
	);
};

export default CaseTemplate;

export const pageQuery = graphql`
	query CaseById($id: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		allWpPost(filter: { slug: { ne: "website-images" } }) {
			nodes {
				blogFields {
					numberOfMinRead
					featuredImage {
						altText
						sourceUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: WEBP
									quality: 80
									transformOptions: { cropFocus: CENTER, fit: COVER }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
				title
				slug
				uri
				excerpt
			}
		}

		wpPage: wpCaseStudy(id: { eq: $id }) {
			slug
			date
			title
			content
			caseStudyFields {
				description
				colour
				authorName
				services {
					... on WpService {
						id
						title
						serviceFields {
							icon {
								altText
								sourceUrl
							}
						}
						slug
					}
				}
				numberOfMinRead
				logo {
					altText
					sourceUrl
				}
				featuredImage {
					altText
					localFile {
						childImageSharp {
							gatsbyImageData(
								formats: WEBP
								quality: 80
								transformOptions: { cropFocus: CENTER, fit: COVER }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				authorImage {
					altText
					localFile {
						childImageSharp {
							gatsbyImageData(
								formats: WEBP
								quality: 80
								transformOptions: { cropFocus: CENTER, fit: COVER }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}

			pageFields {
				fieldGroupName
				components {
					... on WpCaseStudy_Pagefields_Components_Logos {
						fieldGroupName
						items {
							fieldGroupName
							image {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_Header1 {
						body
						fieldGroupName
						heading
						button1 {
							title
							url
						}
						button2 {
							title
							url
						}
						video {
							altText
							sourceUrl
							mediaItemUrl
						}
						overlayColour
						videoDesktop {
							altText
							sourceUrl
							mediaItemUrl
						}
						mediaType
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_Header2 {
						body
						fieldGroupName
						heading
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_Header3 {
						body
						fieldGroupName
						heading
						button1 {
							title
							url
						}
						button2 {
							title
							url
						}
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_TestimonialsVideoWritten {
						body
						companyName
						fieldGroupName
						testimonials {
							... on WpTestimonial {
								id
								title
								testimonialFields {
									companyName
									fieldGroupName
									name
									position
									review
									image {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [WEBP, AUTO]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
							}
						}
						position
						name
						mediaType
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 80
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						logo {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						video {
							altText
							sourceUrl
							mediaItemUrl
						}
					}
					... on WpCaseStudy_Pagefields_Components_TestimonialsVideo {
						body
						companyName
						fieldGroupName
						position
						name
						video {
							altText
							sourceUrl
							mediaItemUrl
						}
						logo {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_TestimonialsWritten {
						fieldGroupName
						testimonials {
							... on WpTestimonial {
								id
								title
								testimonialFields {
									companyName
									fieldGroupName
									name
									position
									review
									image {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [WEBP, AUTO]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_IconsLayout1 {
						body
						fieldGroupName
						heading
						button {
							title
							url
						}
						items {
							url
							heading
							fieldGroupName
							colour
							icon {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_IconsLayout2 {
						fieldGroupName
						heading
						items {
							colour
							body
							heading
							fieldGroupName
							icon {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_IconsLayout3 {
						fieldGroupName
						heading
						items {
							url
							heading
							fieldGroupName
							colour
							body
							icon {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_IconsLayout4 {
						fieldGroupName
						industries {
							... on WpIndustry {
								id
								title
								slug
								industryFields {
									colour
									description
									fieldGroupName
									featuredImage {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [WEBP, AUTO]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
									icon {
										altText
										sourceUrl
									}
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_IconsLayout5 {
						fieldGroupName
						heading
						items {
							colour
							fieldGroupName
							heading
							url
							icon {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							listItems {
								heading
								fieldGroupName
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_GridLayout1 {
						colour1
						colour2
						colour3
						colour4
						colour5
						colour6
						fieldGroupName
						heading
						button {
							title
							url
						}
						industries {
							... on WpIndustry {
								id
								slug
								title
								industryFields {
									featuredImage {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [WEBP, AUTO]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
									description
									fieldGroupName
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_GridLayout2 {
						fieldGroupName
						heading
						items {
							colour
							fieldGroupName
							heading
							url
							listItems {
								heading
								fieldGroupName
							}
							icon {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_GridLayout3 {
						fieldGroupName
						items {
							heading
							fieldGroupName
							image {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_StatsLayout1 {
						fieldGroupName
						heading
						button {
							title
							url
						}
						stat1 {
							body
							heading
							fieldGroupName
						}
						stat2 {
							body
							fieldGroupName
							heading
						}
						stat3 {
							body
							fieldGroupName
							heading
						}
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_StatsLayout2 {
						body
						fieldGroupName
						button {
							url
							title
						}
						heading
						stat1 {
							body
							fieldGroupName
							heading
						}
						stat2 {
							body
							fieldGroupName
							heading
						}
						stat3 {
							body
							fieldGroupName
							heading
						}
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_StatsLayout3 {
						body
						fieldGroupName
						button {
							title
							url
						}
						heading
						stat1 {
							body
							fieldGroupName
							heading
						}
						stat2 {
							body
							fieldGroupName
							heading
						}
						stat3 {
							body
							fieldGroupName
							heading
						}
						stat4 {
							body
							fieldGroupName
							heading
						}
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_Cta1 {
						body
						colour
						heading
						fieldGroupName
						button {
							title
							url
						}
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_Quote {
						body
						colour
						fieldGroupName
					}
					... on WpCaseStudy_Pagefields_Components_CaseStudies {
						fieldGroupName
						caseStudies {
							... on WpCaseStudy {
								id
								caseStudyFields {
									description
									featuredImage {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [WEBP, AUTO]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
								slug
								title
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_SectionLayout1 {
						body
						fieldGroupName
						smallHeading
						heading
						button {
							title
							url
						}
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_SectionLayout2 {
						body
						colour
						colour2
						heading
						fieldGroupName
						button {
							title
							url
						}
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: FULL_WIDTH
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_SectionLayout3 {
						body
						colour
						colour2
						fieldGroupName
						heading
						button {
							title
							url
						}
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_SectionLayout4 {
						body
						colour
						colour2
						heading
						button {
							title
							url
						}
						column1 {
							heading
							body
							fieldGroupName
						}
						column2 {
							body
							fieldGroupName
							heading
						}
						fieldGroupName
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_SectionLayout5 {
						body
						heading
						column1 {
							body
							heading
						}
						column2 {
							body
							heading
						}
						colour
						colour2
						button {
							title
							url
						}
						fieldGroupName
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_SectionLayout6 {
						body
						colour
						heading
						fieldGroupName
						colour2
						button {
							url
							title
						}
						items {
							body
							fieldGroupName
							icon {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_SectionLayout7 {
						body
						colour
						colour2
						fieldGroupName
						heading
						button {
							title
							url
						}
						image1 {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						image2 {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						image3 {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_PssStats {
						fieldGroupName
						stats {
							body
							number
							duration
							colour
							fieldGroupName
							heading
						}
					}
					... on WpCaseStudy_Pagefields_Components_Gallery1 {
						fieldGroupName
						images {
							fieldGroupName
							image {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_Gallery2 {
						fieldGroupName
						images {
							fieldGroupName
							image {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: FULL_WIDTH
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_Team1 {
						body
						fieldGroupName
						teamMembers {
							... on WpTeamMember {
								id
								title
								teamMemberFields {
									body
									fieldGroupName
									linkedinLink
									name
									position
									shortDescription
									twitterLink
									image {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [WEBP, AUTO]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
							}
						}
						heading
						button {
							title
							url
						}
					}
					... on WpCaseStudy_Pagefields_Components_Team2 {
						body
						fieldGroupName
						teamMembers {
							... on WpTeamMember {
								id
								title
								teamMemberFields {
									body
									fieldGroupName
									linkedinLink
									name
									position
									shortDescription
									twitterLink
									image {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [WEBP, AUTO]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
							}
						}
						secondHeading
						heading
						button {
							title
							url
						}
					}
					... on WpCaseStudy_Pagefields_Components_FeaturedBlogs {
						body
						colour
						heading
						fieldGroupName
					}
					... on WpCaseStudy_Pagefields_Components_BlogsCatalogue {
						body
						colour
						fieldGroupName
						heading
					}
					... on WpCaseStudy_Pagefields_Components_FeaturedCaseStudy {
						fieldGroupName
						caseStudy {
							... on WpCaseStudy {
								id
								title
								date
								slug
								caseStudyFields {
									numberOfMinRead
									colour
									authorName
									description
									authorImage {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [WEBP, AUTO]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
									featuredImage {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [WEBP, AUTO]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
								dateGmt
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_CaseStudiesCatalogue {
						colour
						fieldGroupName
					}
					... on WpCaseStudy_Pagefields_Components_Form1 {
						fieldGroupName
						heading
					}
					... on WpCaseStudy_Pagefields_Components_Form2 {
						body
						fieldGroupName
						heading
					}
					... on WpCaseStudy_Pagefields_Components_Form3 {
						fieldGroupName
						heading
						forgotPasswordLink {
							target
							title
							url
						}
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_Form4 {
						fieldGroupName
						heading
						link {
							target
							title
							url
						}
						subheading
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpCaseStudy_Pagefields_Components_Career {
						colour
						fieldGroupName
					}
					... on WpCaseStudy_Pagefields_Components_MultiStepForm {
						colour
						fieldGroupName
					}
					... on WpCaseStudy_Pagefields_Components_Location {
						fieldGroupName
						heading

						locations {
							address
							fieldGroupName
							heading
							url
						}
					}
					... on WpCaseStudy_Pagefields_Components_Faq {
						body
						colour
						secondHeading
						secondBody
						heading
						fieldGroupName
						faqs {
							... on WpFaq {
								id
								title
								faqFields {
									answer
									fieldGroupName
									question
								}
							}
						}
						button {
							title
							url
						}
					}
				}
			}
		}
	}
`;
