import { Link } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaFacebookF, FaLink, FaLinkedin } from "react-icons/fa"; // Example icons from react-icons
import { FaXTwitter } from "react-icons/fa6";
import { SafeHtmlParser } from "./SafeHtmlParser";

const CaseStudyContentSection = ({ wpPage, site }) => {
	const siteUrl = site?.siteMetadata?.siteUrl;
	const postUrl = `${siteUrl}/${wpPage.slug}`;
	const postTitle = encodeURIComponent(wpPage.title);
	const postSummary = encodeURIComponent(
		wpPage.caseStudyFields?.description || ""
	);

	// Share links
	const linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
		postUrl
	)}&title=${postTitle}&summary=${postSummary}`;

	const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
		postUrl
	)}`;

	const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
		postUrl
	)}&text=${postTitle}`;
	return (
		<section className="case-study-content-section py-5">
			<Container>
				<Row className="g-5">
					{/* Left Column: Services & Share */}
					<Col lg={4}>
						<div
							style={{
								borderBottom: `1px solid ${wpPage.caseStudyFields.colour}`,
							}}
							className="mb-lg-5 pb-lg-4 mb-4 pb-3"
						>
							{/* Services */}
							{wpPage.caseStudyFields.services &&
								wpPage.caseStudyFields.services.length > 0 && (
									<>
										<h5 className="mb-3 text-primary">Services</h5>
										<ul className="list-unstyled ">
											{wpPage.caseStudyFields.services.map((service) => (
												<li
													key={service.slug}
													className="mb-2 align-items-center"
												>
													<img
														className="me-3 mb-1"
														src={service.serviceFields.icon.sourceUrl}
														alt={service.serviceFields.icon.altText}
														style={{ maxWidth: "30px" }}
													/>
													<Link
														className="text-decoration-none pb-0 mb-0  outfit-bold"
														to={`/services/${service.slug}`}
													>
														{service.title}
													</Link>
												</li>
											))}
										</ul>
									</>
								)}
						</div>
						{/* Share Section */}
						<h5 className="mb-3 f-6 text-primary">Share</h5>
						<div className="d-flex gap-2">
							<a
								href="#"
								onClick={() =>
									navigator.clipboard.writeText(window.location.href)
								}
								aria-label="Copy link"
							>
								<div
									className="position-relative d-flex align-items-center justify-content-center"
									style={{
										borderRadius: "50%",
										width: "30px",
										height: "30px",
										overflow: "hidden",
									}}
								>
									<div
										style={{
											background: wpPage.caseStudyFields.colour,
											opacity: 0.1,
										}}
										className="w-100 h-100 position-absolute start-0 top-0"
									></div>
									<FaLink
										className="position-relative fs-5"
										style={{ color: wpPage.caseStudyFields.colour, zIndex: 2 }}
									/>
								</div>
							</a>
							<a
								href={linkedinShareUrl}
								target="_blank"
								rel="noopener noreferrer"
								aria-label="Share on LinkedIn"
							>
								<div
									className="position-relative d-flex align-items-center justify-content-center"
									style={{
										borderRadius: "50%",
										width: "30px",
										height: "30px",
										overflow: "hidden",
									}}
								>
									<div
										style={{
											background: wpPage.caseStudyFields.colour,
											opacity: 0.1,
										}}
										className="w-100 h-100 position-absolute start-0 top-0"
									></div>
									<FaLinkedin
										className="position-relative fs-5"
										style={{ color: wpPage.caseStudyFields.colour, zIndex: 2 }}
									/>
								</div>
							</a>
							<a
								href={twitterShareUrl}
								target="_blank"
								rel="noopener noreferrer"
								aria-label="Share on X (Twitter)"
							>
								<div
									className="position-relative d-flex align-items-center justify-content-center"
									style={{
										borderRadius: "50%",
										width: "30px",
										height: "30px",
										overflow: "hidden",
									}}
								>
									<div
										style={{
											background: wpPage.caseStudyFields.colour,
											opacity: 0.1,
										}}
										className="w-100 h-100 position-absolute start-0 top-0"
									></div>
									<FaXTwitter
										className="position-relative fs-5"
										style={{ color: wpPage.caseStudyFields.colour, zIndex: 2 }}
									/>
								</div>
							</a>
							<a
								href={facebookShareUrl}
								target="_blank"
								rel="noopener noreferrer"
								aria-label="Share on Facebook"
							>
								<div
									className="position-relative d-flex align-items-center justify-content-center"
									style={{
										borderRadius: "50%",
										width: "30px",
										height: "30px",
										overflow: "hidden",
									}}
								>
									<div
										style={{
											background: wpPage.caseStudyFields.colour,
											opacity: 0.1,
										}}
										className="w-100 h-100 position-absolute start-0 top-0"
									></div>
									<FaFacebookF
										className="position-relative fs-5"
										style={{ color: wpPage.caseStudyFields.colour, zIndex: 2 }}
									/>
								</div>
							</a>
						</div>
					</Col>

					{/* Right Column: Main Content */}
					<Col className="blog-inner-content" lg={8}>
						{/* Title and Content */}
						{/* If you don't want the title here again, just use the content */}
						<SafeHtmlParser htmlContent={wpPage.content} />
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default CaseStudyContentSection;
